import { getDefaultClient } from "connectkit";
import { AppProps } from "next/app";
import { Toaster } from "react-hot-toast";
import ReactTooltip from "react-tooltip";
import { Chain, configureChains, createClient, WagmiConfig } from "wagmi";
import {
    mainnet,
    optimism,
    arbitrum,
    avalanche,
    bsc,
    polygon
} from "wagmi/chains";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { InjectedConnector } from "wagmi/connectors/injected";
import { publicProvider } from "wagmi/providers/public";
import "../styles/globals.css";
import "../wdyr";
import { infuraProvider } from "wagmi/providers/infura";
import {
    EthereumClient,
    modalConnectors,
    walletConnectProvider
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { Head } from "next/document";
import { Fira_Sans} from '@next/font/google'


// const fira = Fira_Sans({
//     weight: "400",
//     subsets: ['latin'],
//     variable: '--font-fira'
// });

const chains = [mainnet, optimism, arbitrum, avalanche, bsc, polygon];

const alchemyId = process.env.ALCHEMY_ID;
const infuraKey = process.env.INFURA_KEY || "9d44bedf137d4761b04506cf20547948";

const { provider, webSocketProvider } = configureChains(chains, [
    infuraProvider({ apiKey: infuraKey }),
    publicProvider()
]);

const wagmiClient = createClient(
    {
        autoConnect: true,
        connectors: modalConnectors({ appName: "web3Modal", chains }),
        // connectors: [
        //     new MetaMaskConnector({
        //         chains
        //     }),
        //     new WalletConnectConnector({
        //         chains,
        //         options: {
        //             qrcode: true,
        //             version: "2",
        //             projectId: "633aa2aa9b5a364dc7c4ed28fa6d296c"
        //         }
        //     }),
        //     new InjectedConnector({
        //         chains
        //     })
        // ],
        provider,
        webSocketProvider
    }
    // getDefaultClient({
    //     appName: "Sendr",
    //     alchemyId,
    //     chains
    // })
);

const ethereumClient = new EthereumClient(wagmiClient, chains);

function Sendr({ Component, pageProps }: AppProps) {
    // ${fira.variable}
    return (
        <>
            <div className={``}>
                <WagmiConfig client={wagmiClient}>
                    <Component {...pageProps} />
                    <ReactTooltip effect="solid" />
                    <Toaster />
                </WagmiConfig>

                <Web3Modal
                    projectId={"633aa2aa9b5a364dc7c4ed28fa6d296c"}
                    ethereumClient={ethereumClient}
                />
            </div>
        </>
    );
}

export default Sendr;
